import { 
    EAuthActions, 
    ILoginAdmin, 
    ISetAccessToken 
} from "../constants/auth";


export const setAccessToken = (payload:any) : ISetAccessToken => ({
    type: EAuthActions.SET_ACCESS_TOKEN,
    payload,
});

export const setLoginAdmin = (payload:any) : ILoginAdmin => ({
    type: EAuthActions.LOGIN_ADMIN,
    payload,
});
