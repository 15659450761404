import { EOrderStatus } from "../enums/order-status.enum";
import { IBookRequestForm } from "../interfaces/book-request-form.interface";

export enum EBookFormActions {
    UPDATE_STATUS = "UPDATE_STATUS",
}

export interface IUpdateStatusAction {
    type: EBookFormActions.UPDATE_STATUS,
    payload: {
        bookFormId: string,
        userId: string,
        status: EOrderStatus,
    }
}