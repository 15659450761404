import React from "react";
import { IRoute } from "./utils";

const OrdersPage = React.lazy(() => import("../pages/OrdersPage"));

export const orderRoutes:IRoute[] = [
    {
        path: "/orders",
        exact: true,
        component: OrdersPage,
        isPrivate: true, 
    }   
]