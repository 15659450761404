import { EBookActions, ICreateBook, IDeleteBook, ISetCreated, ISetDeleted } from "../constants/book";

export const createBook = (payload:FormData) : ICreateBook => ({
    type: EBookActions.CREATE_BOOK,
    payload,
});

export const setCreated = (payload:boolean) : ISetCreated => ({
    type: EBookActions.SET_CREATED,
    payload,
})

export const deleteBook = (payload:any) : IDeleteBook => ({
    type: EBookActions.DELETE_BOOK,
    payload,
});

export const setDeleted = (payload:boolean) : ISetDeleted => ({
    type: EBookActions.SET_DELETED,
    payload,
})