import React from "react";
import { IRoute } from "./utils";

const LoginPage = React.lazy(() => import("../pages/LoginPage"));

export const authRoutes:IRoute[] = [
    {   
        path: "/login",
        exact: true,
        component: LoginPage,
        isPrivate: false,
    },
]