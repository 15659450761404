import { EBookActions } from "../constants/book";
import { IAction } from "../interfaces/action.interface";

export interface IBookReducer {
    created: boolean,
    deleted: boolean
}

const intialState = {
    created: false,
    deleted: false,
}

const bookReducer = (state:IBookReducer=intialState, action:IAction) => {
    switch(action.type) {
        case EBookActions.SET_CREATED: {
            return { ...state, created: action.payload };
        }
        case EBookActions.SET_DELETED: {
            return { ...state, deleted: action.payload };
        }
        default: {
            return state; 
        }
    }
}

export default bookReducer; 