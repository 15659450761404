import React from 'react';
import { authRoutes } from './auth';
import { bookRoutes } from './books';
import { orderRoutes } from './orders';
import { flattenRoutes, IRoute } from './utils';

const HomePage = React.lazy(() => import("../pages/HomePage"));

const rootRoute:IRoute = {
  path: '/',
  exact: true,
  component: HomePage,
  isPrivate: false,
};

const allRoutes = [
  ...authRoutes,
  rootRoute,
  ...orderRoutes,
  ...bookRoutes
];
const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, allFlattenRoutes };