export enum EAuthActions {
    SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
    LOGIN_ADMIN = "LOGIN_ADMIN"
}

export interface ILoginAdmin {
    type: EAuthActions.LOGIN_ADMIN,
    payload: {
        email: string,
        pass: string,
    }
}
export interface ISetAccessToken {
    type: EAuthActions.SET_ACCESS_TOKEN,
    payload: {
        accessToken: string
    }
}
