export enum EBookActions {
    CREATE_BOOK = "CREATE_BOOK",
    SET_CREATED = "SET_CREATED",
    SET_DELETED = "SET_DELETED",
    DELETE_BOOK = "DELETE_BOOK",
}

export interface ICreateBook {
    type: EBookActions.CREATE_BOOK,
    payload: FormData,
}

export interface ISetCreated {
    type: EBookActions.SET_CREATED,
    payload: boolean,
}

export interface ISetDeleted {
    type: EBookActions.SET_DELETED,
    payload: boolean,
}


export interface IDeleteBook {
    type: EBookActions.DELETE_BOOK,
    payload: {
        bookId: string,
    },
}
