import { all, takeLatest } from "@redux-saga/core/effects";
import { EAuthActions, ILoginAdmin } from "../constants/auth";
import * as api from "../../utils/api";
import { LOGIN_ADMIN } from "../../constants/endpoints/auth";
import { IRequestResponse, IRequestStatus } from "../constants/request";
import { IUser } from "../interfaces/user.interface";
import { put } from "redux-saga/effects";
import { setSnackbarEvent, setUser } from "../actions/user.actions";
import { setAccessToken } from "../actions/auth.actions";   

function* loginAdmin({ payload } : ILoginAdmin ) : Generator<any> {
    try {
        const response : IRequestResponse<IUser> | any = yield api.post(LOGIN_ADMIN, payload);
        if (response?.data?.status !== IRequestStatus.SERVER_ERROR &&
            response?.data?.status !== IRequestStatus.BAD_REQUEST &&
            response.statusCode === IRequestStatus.CREATED) {
            const { data } : { data: IUser } = response; 
            if (data) {
                yield put(setSnackbarEvent({ content: "Access Granted", variant: "success" }))
                localStorage.setItem('user', JSON.stringify(data));
                yield put(setAccessToken({ accessToken: data.accessToken }));
                delete data.accessToken; 
                yield put(setUser(data));
            } else {
                yield put(setSnackbarEvent({ content: "Access Denied", variant: "error" }))
            }
        } else {
            yield put(setSnackbarEvent({ content: "Access Denied", variant: "error"  }))
        };
    } catch {
        yield put(setSnackbarEvent({ content: "Access Denied", variant: "error" }))
    }
}

const saga = function*() {
    yield all([
        takeLatest(EAuthActions.LOGIN_ADMIN, loginAdmin),
    ])
}

export default saga; 