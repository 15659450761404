import { all, put, takeLatest } from "@redux-saga/core/effects";
import { EBookFormActions, IUpdateStatusAction } from "../constants/book-form";
import * as api from "../../utils/api";
import { CREATE_BOOK_FORM, UPDATE_STATUS } from "../../constants/endpoints/book-form";
import { setSnackbarEvent } from "../actions/user.actions";

function* updateStatus({ payload }: IUpdateStatusAction) : Generator<any> {
  try {
    yield api.post(UPDATE_STATUS, payload);
  } catch (e) {
    setSnackbarEvent({ content: "Failed to Delete Book", variant: "error"})
  }
}

const bookFormSaga = function* () {
    yield all([
      takeLatest(EBookFormActions.UPDATE_STATUS, updateStatus)
    ])
}

export default bookFormSaga;