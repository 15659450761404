import { all, put, takeLatest } from "redux-saga/effects"
import { EBookActions, ICreateBook, IDeleteBook } from "../constants/book"
import * as api from "../../utils/api";
import { CREATE_BOOK, DELETE_BOOK } from "../../constants/endpoints/books";
import { setCreated, setDeleted } from "../actions/book.actions";
import { setSnackbarEvent } from "../actions/user.actions";

function* createBook({ payload } : ICreateBook) : Generator<any> {
    try {
        const response:any = yield api.post(CREATE_BOOK, payload);
        if (Object.keys(response).length) {
            yield put(setSnackbarEvent({ content: "Book Created", variant: "success" }));
            yield put(setCreated(true));
        };
    } catch (e) {
        yield put(setSnackbarEvent({ content: "Failed to Create Book", variant: "error" }));
        yield put(setCreated(true));
    };
}

function* deleteBook({ payload } : IDeleteBook) : Generator<any> {
    try {
        const response:any = yield api.deleteRequest(DELETE_BOOK, payload);
        if (response?.statusCode === 200) {
            yield put(setSnackbarEvent({ content: "Succcessfully Deleted", variant: "success" }));
        } else {
            yield put(setSnackbarEvent({ content: "Failed to Delete Book", variant: "error" }));
        }
        yield put(setDeleted(true));
    } catch (e) {
        yield put(setSnackbarEvent({ content: "Failed to Delete Book", variant: "error" }));
        yield put(setDeleted(true));
    }
};


const bookSaga = function* () {
    yield all([
        takeLatest(EBookActions.DELETE_BOOK, deleteBook),
        takeLatest(EBookActions.CREATE_BOOK, createBook),
    ])
}

export default bookSaga; 